import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'

import { fetchLoginState } from './actions/auth'
import { initAuthService } from './modules/authService'
import { initAPI } from './api'
import globalRestOptions from './backend/rest_config'

// MARK: - Middlewares
import thunk from 'redux-thunk'
import authHeadersMiddleware from './middlewares/auth'
import fetchTransformMiddleware from './middlewares/fetch_transform'
import restMiddlewareCreator from 'redux-fetch-middleware'
import createSagaMiddleware from 'redux-saga'
import toast from './middlewares/toast'
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import productPrice from './middlewares/productPrice'

import translations from './i18n'
import rootSaga from './sagas'

import 'isomorphic-fetch'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://33d1aabd907f5b0fd9eef148ba256f13@o4508306010800128.ingest.us.sentry.io/4508346441859072',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/coral-backoffice\.travelism\.co\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const saga = createSagaMiddleware()

const middlewares = [
  thunk,
  authHeadersMiddleware,
  fetchTransformMiddleware,
  restMiddlewareCreator(globalRestOptions),
  toast,
  saga,
  productPrice,
]

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(...middlewares),
)

saga.run(rootSaga)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations))
store.dispatch(setLocale('en'))

// if (process.env['NODE_ENV'] === 'development')
//   store.dispatch(fetchUser({ username: 'staff', password: '1234' }));
// else
store.dispatch(fetchLoginState())

initAPI(store)
initAuthService(store)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// registerServiceWorker();
unregister()
